import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import IndexPage from "./pages/Index.js";
import CartPage from "./pages/cart.js";
import CheckOutPage from "./pages/checkout.js";
import OrderHistoryPage from "./pages/orderhistory.js";
import ProductsPage from "./pages/products.js";
import ProductViewPage from "./pages/product-info.js";
import SignUpPage from "./pages/signup.js";
import SignInPage from "./pages/signin.js";
import UppyFileUpload from "./pages/uppyfileupload.js";
import ChangePassword from "./pages/changepassword.js";
import MyProfile from "./pages/myprofile.js";
import Customize from "./pages/customize.js";
import {
  callCategoryList,
  callColorList,
  callMockCategoryList,
  callProductsColorList,
  callProductsList,
  callProductsSizeList,
  callSizeList,
  callStores,
  callVersionMangerList,
} from "./utilities/storeManager.js";
import { getUserdata } from "./utilities/sessionexpiry.js";
import {
  handleGetInfoStorageItems,
  handleSetInfoStorageItems,
} from "./utilities/storageManager.js";
import { SK } from "./constants/StorageKeys.js";
import {
  handleGetCartInfoStorageItems,
  handleSetCartInfoStorageItems,
} from "./utilities/cartManager.js";
import { checkerString, lowercasenosp } from "./utilities/checker.js";
import OrderDetails from "./pages/orderdetails.js";

function App(props) {
  const [pageinit, setPageInit] = useState(false);
  const [assetsUrl, setAssetsUrl] = useState(null);
  const [storeinfo, setStoreInfo] = useState({
    storeid: null,
    store: "",
    description: "",
    assets: "",
    hdimage: "",
    productimage: "",
    productviewimage: "",
    thumbnailviewimage: "",
  });
  const [categoryListData, setCategoryListData] = useState([]);
  const [userData, setUserData] = useState({});
  const [showFooter, setshowFooter] = useState(true);
  const [sizeListData, setSizeListData] = useState([]);

  const [productsListData, setProductsListData] = useState([]);
  const [productssizeListData, setProductsSizeListData] = useState([]);
  const [productscolorcodesListData, setProductsColorCodesListData] = useState(
    []
  );
  const [colorcodesListData, setColorCodesListData] = useState([]);
  const [versionmanagerListData, setVersionManagerListData] = useState([]);
  const [count, setCount] = useState(1);
  let cartinfodefalults = {
    cartcount: 0,
    cartquantitycount: 0,
    products: [],
    cartprice: 0,
    subtotal: 0,
    mrptotal: 0,
    shipping: 0,
    ordertotal: 0,
    total: 0,
  };

  let storetextdefalults = {
    storeid: process.env.REACT_APP_STORE_ID,
    footercopyrighttext: "",
    mainpagebannerbgcolor: "#ffffff",
    mainpagebanner1text: "",
    mainpagebanner2text: "",
    mainpageheader1text: "",
    mainpageheader2text: "",
  };

  const [cartinfoData, setCartInfoData] = useState(cartinfodefalults);
  const [storeText, setStoreText] = useState(storetextdefalults);

  useEffect(() => {
    console.log("***");
  }, [count]);

  const clearCartInfoData = async () => {
    handleSetCartInfoStorageItems(cartinfodefalults);
    setCartInfoData(cartinfodefalults);
    setProductsListData(await callProductsList(null, true, true));
    setCount({ ...count, count: count + 1 });
  };

  const pageRefresh = async (productlist = false) => {
    setUserData(getUserdata());
    setCount({ ...count, count: count + 1 });
    if (productlist) {
      let hardreset = false;
      let mockupdata = true;
      let _productlistdata = await callProductsList(
        null,
        hardreset,
        mockupdata
      );
      setProductsListData(_productlistdata === null ? [] : _productlistdata);
      setCount({ ...count, count: count + 1 });
      return _productlistdata;
    }
    return await handleGetCartInfoStorageItems(setCartInfoData);
  };

  const callpageInit = async () => {
    let version = handleGetInfoStorageItems(SK.VERSIONINFODATA);
    let hardreset = false;
    let mockupdata = false;
    if (
      version === null ||
      version === undefined ||
      isNaN(Number(version)) ||
      Number(version) === null ||
      Number(version) === undefined ||
      Number(process.env.REACT_APP_VERSION) !== Number(version)
    ) {
      console.log("mockup true");
      handleSetInfoStorageItems(
        SK.VERSIONINFODATA,
        process.env.REACT_APP_VERSION
      );
      setCount({ ...count, count: count + 1 });
      mockupdata = true;
    }

    // setAssetsUrl(await callStores("assets", hardreset, true));
    let storedetails = await callStores(null, hardreset, mockupdata);
    setStoreInfo(storedetails);

    let _categorylistdata = await await callCategoryList(
      null,
      hardreset,
      mockupdata
    );
    setCategoryListData(_categorylistdata === null ? [] : _categorylistdata);

    let _sizelistdata = await callSizeList(null, hardreset, mockupdata);
    setSizeListData(_sizelistdata === null ? [] : _sizelistdata);

    let _productlistdata = await callProductsList(null, hardreset, mockupdata);
    setProductsListData(_productlistdata === null ? [] : _productlistdata);

    let _prodictsizelistdata = await callProductsSizeList(
      null,
      hardreset,
      mockupdata
    );
    setProductsSizeListData(
      _prodictsizelistdata === null ? [] : _prodictsizelistdata
    );

    let _colorcodelistdata = await callColorList(null, hardreset, mockupdata);
    setColorCodesListData(
      _colorcodelistdata === null ? [] : _colorcodelistdata
    );

    let _productscolorcodelistdata = await callProductsColorList(
      null,
      hardreset,
      mockupdata
    );
    setProductsColorCodesListData(
      _productscolorcodelistdata === null ? [] : _productscolorcodelistdata
    );

    setVersionManagerListData(
      await callVersionMangerList(null, hardreset, mockupdata)
    );

    if (hardreset) {
      clearCartInfoData();
    } else {
      handleGetCartInfoStorageItems(setCartInfoData);
    }

    if (lowercasenosp(storedetails.store) === lowercasenosp("tamiltshirts")) {
      storetextdefalults.footercopyrighttext = `© Copyright 2012 - ${new Date().getFullYear()} Tamil Tshirts`;
      storetextdefalults.mainpagebanner1text = "Tamil Printed Tshirts Factory";
      storetextdefalults.mainpagebanner2text = "Customized Printed Tshirts";
      storetextdefalults.mainpageheader1text =
        "Elevate Your Style with Unique <br/> Printed T-Shirts.";
      storetextdefalults.mainpageheader2text =
        "Discover more products and inspiration.";

      setStoreText(storetextdefalults);
    } else if (
      lowercasenosp(storedetails.store) === lowercasenosp("teemania")
    ) {
      storetextdefalults.footercopyrighttext = `© Copyright ${new Date().getFullYear()} Teemania`;
      storetextdefalults.mainpagebanner1text =
        "Beautiful Quotes Printed Tshirts Factory";
      storetextdefalults.mainpagebanner2text = "Customized Printed Tshirts";
      storetextdefalults.mainpageheader1text =
        "Elevate Your Style with Unique <br/> Printed T-Shirts.";
      storetextdefalults.mainpageheader2text =
        "Discover more products and inspiration.";
      setStoreText(storetextdefalults);
    } else if (
      lowercasenosp(storedetails.store) === lowercasenosp("thaithingal")
    ) {
      storetextdefalults.mainpagebannerbgcolor = "#d6aa33";
      storetextdefalults.footercopyrighttext = `© Copyright ${new Date().getFullYear()} ThaiThingal`;
      storetextdefalults.mainpagebanner1text = "";
      storetextdefalults.mainpagebanner2text = "";
      storetextdefalults.mainpageheader1text = "";
      storetextdefalults.mainpageheader2text = "";
      setStoreText(storetextdefalults);
    }

    setCount({ ...count, count: count + 1 });

    // setAssetsUrl(await callStores("assets"));
    // setCategoryListData(await callCategoryList());
    // setSizeListData(await callSizeList());
    // setProductsListData(await callProductsList());
    // setProductsSizeListData(await callProductsSizeList());
    // setColorCodesListData(await callColorList());
    // setProductsColorCodesListData(await callProductsColorList());
    // setVersionManagerListData(await callVersionMangerList());

    setUserData(getUserdata());
    setCount({ ...count, count: count + 1 });
  };

  if (!pageinit) {
    setPageInit(true);
    callpageInit();
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <IndexPage
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            mainpagebannerbgcolor={storeText.mainpagebannerbgcolor}
            mainpageheader1text={storeText.mainpageheader1text}
            mainpageheader2text={storeText.mainpageheader2text}
            mainpagebanner1text={storeText.mainpagebanner1text}
            mainpagebanner2text={storeText.mainpagebanner2text}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/cart"
        element={
          <CartPage
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            count={count}
            setCount={setCount}
            assetsUrl={assetsUrl}
            categoryListData={categoryListData}
            productsListData={productsListData}
            userData={userData}
            sizeListData={sizeListData}
            productssizeListData={productssizeListData}
            productscolorcodesListData={productscolorcodesListData}
            colorcodesListData={colorcodesListData}
            versionmanagerListData={versionmanagerListData}
            cartinfoData={cartinfoData}
            setCartInfoData={setCartInfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/checkout"
        element={
          <CheckOutPage
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            count={count}
            setCount={setCount}
            assetsUrl={assetsUrl}
            categoryListData={categoryListData}
            productsListData={productsListData}
            userData={userData}
            setUserData={setUserData}
            sizeListData={sizeListData}
            productssizeListData={productssizeListData}
            productscolorcodesListData={productscolorcodesListData}
            colorcodesListData={colorcodesListData}
            versionmanagerListData={versionmanagerListData}
            cartinfoData={cartinfoData}
            setCartInfoData={setCartInfoData}
            clearCartInfoData={clearCartInfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/orderhistory"
        element={
          <OrderHistoryPage
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            categoryListData={categoryListData}
            productsListData={productsListData}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/orderdetails"
        element={
          <OrderDetails
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            categoryListData={categoryListData}
            productsListData={productsListData}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/products/:id?"
        element={
          <ProductsPage
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            count={count}
            setCount={setCount}
            assetsUrl={assetsUrl}
            categoryListData={categoryListData}
            productsListData={productsListData}
            userData={userData}
            sizeListData={sizeListData}
            productssizeListData={productssizeListData}
            productscolorcodesListData={productscolorcodesListData}
            colorcodesListData={colorcodesListData}
            versionmanagerListData={versionmanagerListData}
            cartinfoData={cartinfoData}
            setCartInfoData={setCartInfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/p/:id"
        element={
          <ProductViewPage
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            count={count}
            setCount={setCount}
            assetsUrl={assetsUrl}
            categoryListData={categoryListData}
            productsListData={productsListData}
            userData={userData}
            sizeListData={sizeListData}
            productssizeListData={productssizeListData}
            productscolorcodesListData={productscolorcodesListData}
            colorcodesListData={colorcodesListData}
            versionmanagerListData={versionmanagerListData}
            cartinfoData={cartinfoData}
            setCartInfoData={setCartInfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/signup"
        element={
          <SignUpPage
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            cartinfoData={cartinfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/signin"
        element={
          <SignInPage
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            cartinfoData={cartinfoData}
            pageRefresh={pageRefresh}
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/customize"
        element={
          <UppyFileUpload
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/changepassword"
        element={
          <ChangePassword
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/myprofile"
        element={
          <MyProfile
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
      <Route
        path="/old"
        element={
          <Customize
            store={storeinfo.store}
            description={storeinfo.description}
            assets={storeinfo.assets}
            storeid={storeText.storeid}
            footercopyrighttext={storeText.footercopyrighttext}
            hdimage={checkerString(storeinfo.hdimage) ? storeinfo.hdimage : ""}
            productimage={
              checkerString(storeinfo.productimage)
                ? storeinfo.productimage
                : ""
            }
            productviewimage={
              checkerString(storeinfo.productviewimage)
                ? storeinfo.productviewimage
                : ""
            }
            thumbnailviewimage={
              checkerString(storeinfo.thumbnailviewimage)
                ? storeinfo.thumbnailviewimage
                : ""
            }
            showFooter={showFooter}
            setshowFooter={setshowFooter}
          />
        }
      />
    </Routes>
  );
}

export default App;

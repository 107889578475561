import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import img1 from "./img1-.jpg";
import img2 from "./img2-.jpg";
import img3 from "./img3-.jpg";
import img4 from "./img-4.webp";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
export default () => {
  return (
    <Swiper
      // install Swiper modules
      style={{ height: "fit-content" }}
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={5}
      loop={false}
      speed={1500}
      autoplay={{ delay: 1800, disableOnInteraction: false }}
      //slidesPerView={1}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
      breakpoints={{
        // when window width is >= 640px
        640: {
          width: 640,
          slidesPerView: 1,
        },
        // when window width is >= 768px
        768: {
          slidesPerView: 3,
        },
      }}
    >
      <SwiperSlide
        className="swiperslider"
        style={{ height: "auto", width: "auto" }}
      >
        <img src={img1} style={{ height: "auto", width: "auto" }}></img>
      </SwiperSlide>
      <SwiperSlide className="swiperslider">
        <img src={img2}></img>
      </SwiperSlide>
      <SwiperSlide className="swiperslider">
        {" "}
        <img src={img3}></img>
      </SwiperSlide>
      <SwiperSlide className="swiperslider">
        <img src={img4}></img>
      </SwiperSlide>
    </Swiper>
  );
};

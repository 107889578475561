import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { PiCurrencyInr } from "react-icons/pi";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { BsFillCartXFill } from "react-icons/bs";
import {
  handleCartCartInfoMinus,
  handleCartCartInfoPlus,
  handleCartMinus,
  handleCartPlus,
  handleFetchCategoryData,
  handleFetchProductsData,
  handleGetCartInfoStorageItems,
  handleSetCartInfoStorageItems,
} from "../utilities/cartManager";
import "./customstyle.css";
import { getUserdata } from "../utilities/sessionexpiry";
import { checkerArray } from "../utilities/checker";
import { lowercasenosp } from "../utilities/checker";
const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Cart(props) {
  const {
    store,
    description,
    assets,
    storeid,
    hdimage,
    productimage,
    productviewimage,
    thumbnailviewimage,
    assetsUrl,
    categoryListData,
    userData,
    versionmanagerListData,
    colorcodesListData,
    productscolorcodesListData,
    sizeListData,
    productssizeListData,
    productsListData,
    cartinfoData,
    setCartInfoData,
    count,
    setCount,
    pageRefresh,
    footercopyrighttext,
    showFooter,
    setshowFooter,
  } = props;
  const [pageinit, setPageInit] = useState(false);
  const [showFormLoader, setFormLoader] = useState(false);
  const [showpromocodePopup, setshowpromocodePopup] = useState(false);
  const [showcouponsuccessPopup, setcouponsuccessPopup] = useState(false);

  const [couponlist, setcouponlist] = useState([
    {
      code: "VILVA021",
    },
  ]);

  const [hiddencouponinvalid, sethiddencouponinvalid] = useState(true);
  const [hiddencouponadded, sethiddencouponadded] = useState(true);

  const [couponAndGiftCardInput, setcouponAndGiftCardInput] = useState("");
  const [cartcouponlist, setcartcouponlist] = useState([]);

  const [availableData, setAvailabeData] = useState({
    colorcodes: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
    size: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
    products: [{ id: 110 }, { id: 111 }, { id: 112 }, { id: 113 }, { id: 114 }],
  });
  useEffect(() => {
    if (cartinfoData.cartcount > 5) {
      console.log("***");
    } else {
      console.log("***");
    }
  }, [cartinfoData.cartcount, cartinfoData, cartinfoData.products]);

  const getcartCount = () => {
    // return cartinfoData.cartcount;
    return cartinfoData.cartcount;
  };

  const filterSize = (size) => {
    if (size === null) return true;
    let filtered = availableData.size.filter((avsize) => {
      if (avsize.id === size.id) return true;
    });
    if (filtered.length > 0) return true;
  };

  if (!pageinit) {
    setPageInit(true);
    pageRefresh();
  }
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        {NavBarCart(store, getcartCount, userData)}

        <section className="relative lg:py-4 testing-1 md:py-4 testing-1 container-y">
          <div
            className="container mx-auto lg:px-4 md:px-4"
            hidden={getcartCount() == 0}
          >
            <div className="cartWrapper">
              <div className="margin-mobile">
                {" "}
                <div className="cartDeskHead container">
                  <span className="qty">
                    <b>My Cart </b>
                    {getcartCount()} item
                  </span>
                </div>
                <div className="bagWapperWithItems">
                  <div className="container cartContainer">
                    <div className="container-fluid" style={{ padding: 0 }}>
                      <div className="col-sm-7 noPd">
                        <div className="df-wrap">
                          <div
                            className="df-inner"
                            style={{
                              backgroundColor: "rgb(252, 255, 238)",
                              height: 50,
                            }}
                          >
                            <img
                              src="https://images.bewakoof.com/web/Red-truck.png"
                              alt="truck"
                              className="df-img"
                              style={{
                                width: 19,
                                height: 12,
                                animationDuration: "2s",
                              }}
                            />
                            <p
                              style={{
                                fontSize: 12,
                                color: "black",
                                fontFamily: "Montserrat",
                              }}
                            >
                              Yay! You get FREE delivery on this order
                            </p>
                          </div>
                        </div>
                        <div className="leftSection">
                          {cartinfoData.products.map((product) => (
                            <div>
                              <div id="">
                                <div className="cartProductBorder clearfix">
                                  <div className="cartProduct">
                                    {/* <div className="prod-offer-wrap">
                                      <div className="prod-offer-text">
                                        <p className="prod-offer-head">
                                          Buy 3 For 1199 offer applicable
                                        </p>
                                        <p className="prod-offer-desc">
                                          Add 2 more item to avail this offer
                                        </p>
                                      </div>
                                      <div>
                                        <button
                                          className="prod-offer-btn"
                                          style={{
                                            color: "rgb(234, 128, 0)",
                                            fontSize: 16,
                                            fontFamily: "montserrat",
                                          }}
                                        >
                                          Add items
                                        </button>
                                      </div>
                                    </div> */}
                                    <div className="cartProductInner">
                                      <div className="">
                                        <div className="prod-row">
                                          <div className="cartProdText">
                                            <span>
                                              <a
                                                className="cartProductName"
                                                aria-current="false"
                                                href="#"
                                              >
                                                {product.name}
                                              </a>
                                            </span>
                                            <div className="productPriceDetails clearfix">
                                              <span className="cartProductPrice">
                                                <b>₹</b>
                                                {product.sp}
                                              </span>
                                              <span className="cartProductMrp">
                                                ₹{product.mrp}
                                              </span>
                                            </div>
                                            <div className="cart-prod-info-msg">
                                              You saved ₹
                                              {product.mrp - product.sp}!
                                            </div>
                                            {/* <div className="prod-offer-apply">
                                              <div className="img-wrap">
                                                <img
                                                  className="img-outer"
                                                  src="https://images.bewakoof.com/web/BXGY-icon-orange-1608789467.png"
                                                  alt=""
                                                />
                                                <img
                                                  className="img-inner"
                                                  src="https://images.bewakoof.com/web/BXGY-icon-white-1608789491.png"
                                                  alt=""
                                                />
                                              </div>
                                              <p>
                                                Buy 3 For 1199 offer applicable
                                              </p>
                                            </div>{" "} */}
                                            <div className="cartModOptionWrap">
                                              <div className="cartModOptionInner">
                                                <div className="cartModOptions ">
                                                  <span className="">
                                                    Size :{" "}
                                                    <b id="testChangeSize">S</b>
                                                  </span>
                                                  <i className="icon_down" />
                                                </div>
                                              </div>
                                              <div className="cartModOptionInner">
                                                <div className="cartModOptions">
                                                  <span>
                                                    Qty :{" "}
                                                    <b id="testChangeQty">1</b>
                                                  </span>{" "}
                                                  <i className="icon_down" />
                                                </div>
                                              </div>
                                            </div>{" "}
                                          </div>
                                          <div className="cartProductImg">
                                            <a aria-current="false" href="#">
                                              <img
                                                src={
                                                  assets +
                                                  productimage +
                                                  String(
                                                    checkerArray(
                                                      product.imageurl.split(
                                                        ","
                                                      ),
                                                      1
                                                    )
                                                      ? product.imageurl.split(
                                                          ","
                                                        )[0]
                                                      : ""
                                                  )
                                                }
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null; // prevents looping
                                                  currentTarget.src =
                                                    "yofte-assets/images/no-image.webp";
                                                }}
                                                title="Men's Green Cyber Samurai Graphic Printed T-shirt"
                                                alt="Men's Green Cyber Samurai Graphic Printed T-shirt"
                                                loading="lazy"
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="cartBottomAction">
                                      <div className="cartProductActions">
                                        <div
                                          id="testRemoveCart"
                                          className="rmv-action"
                                          onClick={() => {
                                            let _cartinfodata = cartinfoData;
                                            _cartinfodata.products =
                                              cartinfoData.products?.filter(
                                                (_product) => {
                                                  return (
                                                    _product.id !== product.id
                                                  );
                                                }
                                              );
                                            setCartInfoData(_cartinfodata);
                                            handleSetCartInfoStorageItems(
                                              _cartinfodata
                                            );
                                            setCount({
                                              ...count,
                                              count: count + 1,
                                            });
                                          }}
                                        >
                                          {" "}
                                          Remove{" "}
                                        </div>
                                        <div
                                          id="testSavefrLater"
                                          className="add-w-action"
                                        >
                                          {" "}
                                          Move to Wishlist{" "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <span id="SizeList">
                                <div />
                              </span>
                              <span id="QuantityList">
                                <div />
                              </span>
                              <span id="removeOrWishlist">
                                <div />
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="summaryBox col-sm-5 rightSection noPdRight">
                        <span
                          id="CouponSuccess"
                          hidden={!showcouponsuccessPopup}
                        >
                          <div>
                            <div className="close_popup_target popup_backdrop slideUpToMiddle">
                              <div
                                className="popupBody"
                                style={{ borderRadius: 5 }}
                              >
                                <div className="couponReedemBox clearfix">
                                  <div className="couponReedemSuccess">
                                    <div className="successCheckWrapper">
                                      <svg
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 130.2 130.2"
                                      >
                                        <circle
                                          className="path circle"
                                          fill="none"
                                          stroke="#fdd734"
                                          strokeWidth={8}
                                          strokeMiterlimit={10}
                                          cx="65.1"
                                          cy="65.1"
                                          r="60.1"
                                        />
                                        <polyline
                                          className="path check"
                                          fill="none"
                                          stroke="#fdd734"
                                          strokeWidth={8}
                                          strokeLinecap="round"
                                          strokeMiterlimit={10}
                                          points="100.2,40.2 51.5,88.8 29.8,67.5 "
                                        />
                                      </svg>
                                    </div>
                                    <span className="couponSuccessMsg">
                                      Coupon Applied Successfully!
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                        {/* <div
                          className="cart-story-tribe"
                          style={{ height: 50 }}
                        >
                          <div className="cart-story-tribe-text">
                            <p
                              style={{ fontSize: 14, fontFamily: "montserrat" }}
                            >
                              Save extra{" "}
                              <strong style={{ fontFamily: "montserrat-bold" }}>
                                ₹40
                              </strong>{" "}
                              with{" "}
                              <img
                                alt="TriBe"
                                height={17}
                                width={38}
                                src="https://images.bewakoof.com/web/ic-cm-tribe-lg.svg"
                              />
                            </p>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div className="icon_next_one">
                              <i className="icon_next " />
                            </div>
                            <div className="icon_next_two">
                              <i
                                className="icon_next"
                                style={{ marginLeft: "-7px" }}
                              />
                            </div>
                          </div>
                        </div> */}
                        <div>
                          <span id="OfferRows">
                            <div />
                          </span>
                          <div
                            className="offerBox"
                            style={{
                              marginBottom: 15,
                              borderRadius: 4,
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "rgb(234, 234, 234)",
                              backgroundColor: "rgb(255, 255, 255)",
                              color: "rgb(45, 45, 45)",
                              fontFamily: "Montserrat-Regular",
                              lineHeight: "1.44",
                              fontSize: 14,
                              padding: "5px 15px",
                            }}
                          >
                            <div>
                              Whistles! Get extra 15% cashback on prepaid orders
                              above Rs.699. Coupon code - VILVA021
                            </div>
                          </div>
                        </div>
                        <div>
                          <span id="loginConf">
                            <div />
                          </span>
                          <span id="otpConf">
                            <div />
                          </span>
                          <span id="promocode" hidden={!showpromocodePopup}>
                            <div>
                              <div className="close_popup_target popup_backdrop slideUpToMiddle">
                                <div
                                  className="popupBody"
                                  style={{ borderRadius: 5 }}
                                >
                                  <i
                                    id="promocode"
                                    className="close_popup_target icon_close"
                                    onClick={() => {
                                      sethiddencouponadded(true);
                                      sethiddencouponinvalid(true);
                                      setcouponAndGiftCardInput("");
                                      setshowpromocodePopup(false);
                                    }}
                                  />
                                  <div className="couponReedemBox clearfix">
                                    <div className="applyCoupon">
                                      <h3>Apply Coupon / Gift Card</h3>
                                      <form>
                                        <div className="xgroup">
                                          <input
                                            className="form-control"
                                            type="text"
                                            id="couponAndGiftCardInput"
                                            placeholder=""
                                            defaultValue=""
                                            value={couponAndGiftCardInput}
                                            onChange={(e) => {
                                              setcouponAndGiftCardInput(
                                                e.target.value.toUpperCase()
                                              );
                                              if (e.target.value)
                                                document
                                                  .getElementById(
                                                    "entercodelabel"
                                                  )
                                                  .classList.add("active");
                                              else
                                                document
                                                  .getElementById(
                                                    "entercodelabel"
                                                  )
                                                  .classList.remove("active");
                                            }}
                                          />
                                          <span className="bar focus" />
                                          <label
                                            id="entercodelabel"
                                            htmlFor="fname"
                                            className="active"
                                          >
                                            ENTER CODE
                                          </label>
                                          <div
                                            hidden={
                                              !hiddencouponinvalid ||
                                              !hiddencouponadded
                                            }
                                          >
                                            <br />
                                          </div>
                                          <div
                                            className="feedback-wrapper"
                                            hidden={hiddencouponinvalid}
                                          >
                                            <div className="feedback-msg error">
                                              Invalid Code
                                            </div>
                                          </div>
                                          <div
                                            className="feedback-wrapper"
                                            hidden={hiddencouponadded}
                                          >
                                            <div className="feedback-msg error">
                                              Coupon Already Added!
                                            </div>
                                          </div>
                                        </div>
                                      </form>
                                      <button
                                        className=""
                                        onClick={() => {
                                          sethiddencouponadded(true);
                                          sethiddencouponinvalid(true);
                                          if (couponAndGiftCardInput === "") {
                                            sethiddencouponinvalid(false);
                                          } else {
                                            let couponfilter =
                                              couponlist.filter((_coupon) => {
                                                return (
                                                  _coupon.code ===
                                                  couponAndGiftCardInput
                                                );
                                              });
                                            if (couponfilter.length > 0) {
                                              if (
                                                cartcouponlist.filter(
                                                  (cartcoupon) => {
                                                    return (
                                                      couponfilter[0].code ===
                                                      cartcoupon.code
                                                    );
                                                  }
                                                ).length > 0
                                              ) {
                                                sethiddencouponadded(false);
                                              } else {
                                                let cartcoupon = cartcouponlist;

                                                cartcoupon.push(
                                                  couponfilter[0]
                                                );
                                                setcartcouponlist(cartcoupon);
                                                setshowpromocodePopup(false);
                                                setcouponsuccessPopup(true);
                                                setTimeout(() => {
                                                  setcouponsuccessPopup(false);
                                                  setCount({
                                                    ...count,
                                                    count: count + 1,
                                                  });
                                                }, 1000);
                                              }
                                            } else {
                                              sethiddencouponinvalid(false);
                                            }
                                          }
                                        }}
                                      >
                                        APPLY
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                        <div
                          className="redeem-button-wraper"
                          onClick={() => {
                            sethiddencouponadded(true);
                            sethiddencouponinvalid(true);
                            setcouponAndGiftCardInput("");
                            document
                              .getElementById("entercodelabel")
                              .classList.remove("active");
                            setshowpromocodePopup(true);
                          }}
                        >
                          <div className="redeem-button d-flex align-items-center justify-content-between">
                            <span>Apply Coupon / Gift Card / Referral</span>
                            <span className="d-flex align-items-center">
                              Redeem
                              <img
                                src="https://images.bewakoof.com/web/coupon-redeem-arrow-1634641878.png"
                                alt=""
                              />
                            </span>
                          </div>
                        </div>

                        {cartcouponlist.map((_couponlist) => (
                          <>
                            <div
                              className="coupon-wrap"
                              style={{
                                position: "relative",
                                marginBottom: 15,
                                borderRadius: 4,
                                borderStyle: "solid",
                                borderColor: "rgb(234, 234, 234)",
                                backgroundColor: "rgba(253, 216, 53, 0.1)",
                                padding: "10px 15px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="coupon-success-img">
                                  <img
                                    className="success-img"
                                    src="https://images.bewakoof.com/web/teenyicons-tick-circle-solid-1614248395.png"
                                    alt="coupoun success img"
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "inline-block",
                                    paddingRight: 5,
                                    fontFamily: "Montserrat-Bold",
                                    fontWeight: "bold",
                                    fontSize: 12,
                                    color: "rgb(0, 0, 0)",
                                    lineHeight: "1.9",
                                    marginLeft: 5,
                                  }}
                                >
                                  Coupon Applied
                                </div>
                                <span
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    padding: "15px 10px",
                                    color: "rgb(199, 24, 24)",
                                    fontSize: 11,
                                    fontWeight: 900,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    let filteredcoupon = cartcouponlist.filter(
                                      (_ccpl) => {
                                        return _ccpl.code !== _couponlist.code;
                                      }
                                    );
                                    setcartcouponlist(filteredcoupon);
                                  }}
                                >
                                  REMOVE
                                </span>
                                <span className="coupon">VILVA021</span>
                              </div>
                              <div>
                                <p className="cb-text">
                                  Cashback of{" "}
                                  <strong>
                                    ₹{(cartinfoData.total * 10) / 100}
                                  </strong>{" "}
                                  will be credited to your {store} wallet post
                                  successful delivery of your order
                                </p>
                              </div>
                            </div>
                          </>
                        ))}

                        <span id="loginConf">
                          <div />
                        </span>
                        <div className="summeryBorderBox prc-summary">
                          <div className="sectionTopHeading">
                            <h4>Price Summary</h4>
                          </div>
                          <div className="bx-pdg">
                            <div className="paymentBox">
                              <div className="prc-bdn prc-bdn-dsk">
                                <div className="d-flex justify-content-between w100 paymentBoxInner ">
                                  <p className="f-b3-r p-brk-dwn false">
                                    Total MRP (Incl. of taxes)&nbsp;
                                  </p>
                                  <p className="f-b3-r p-brk-dwn false">
                                    ₹{cartinfoData.mrptotal}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between w100 paymentBoxInner ">
                                  <p className="f-b3-r p-brk-dwn false">
                                    Shipping Charges&nbsp;
                                  </p>
                                  <p
                                    className="f-b3-r p-brk-dwn false"
                                    style={{ color: "rgb(29, 136, 2)" }}
                                  >
                                    FREE
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between w100 paymentBoxInner ">
                                  <p className="f-b3-r p-brk-dwn false">
                                    Cart Discount&nbsp;
                                  </p>
                                  <p className="f-b3-r p-brk-dwn false">
                                    - ₹
                                    {cartinfoData.mrptotal - cartinfoData.total}
                                  </p>
                                </div>
                                <div
                                  className="d-flex justify-content-between w100 paymentBoxInner "
                                  style={{
                                    fontFamily:
                                      "montserrat-semibold, sans-serif",
                                  }}
                                >
                                  <p className="f-b3-r p-brk-dwn p-brk-dwn-st">
                                    Subtotal&nbsp;
                                  </p>
                                  <p className="f-b3-r p-brk-dwn p-brk-dwn-st">
                                    ₹{cartinfoData.total}
                                  </p>
                                </div>
                              </div>
                              <div className="hidden lg:block md:block xl:block 2xl:block">
                                <div className="pmts-box">
                                  <div className="pmts-wrap">
                                    <div className="pmts-pr">
                                      <span>Total</span>
                                      <div className="d-flex">
                                        <p>₹</p>
                                        <p>{cartinfoData.total}</p>
                                      </div>
                                    </div>
                                    <a
                                      id="os_payNow_btn"
                                      className="pmts-btn text-center"
                                      style={{
                                        backgroundColor: "rgb(66, 162, 162)",
                                        borderColor: "rgb(66, 162, 162)",
                                        color: "white",
                                      }}
                                      href="/checkout"
                                    >
                                      Go to checkout
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="hidden lg:block md:block xl:block 2xl:block">
                            <div className="cartInline">
                              <div className="trustBaggeContainer d-flex flex-column">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-row  containerInner">
                                    <div className="d-flex flex-column align-items-center">
                                      <img
                                        loading="lazy"
                                        alt="offer"
                                        src="https://images.bewakoof.com/web/cart-badge-trust.svg"
                                      />
                                      <span className="ProductText">
                                        100% SECURE PAYMENTS
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-row  containerInner">
                                    <div className="d-flex flex-column align-items-center">
                                      <img
                                        loading="lazy"
                                        alt="offer"
                                        src="https://images.bewakoof.com/web/cart-easy-return.svg"
                                      />
                                      <span className="ProductText">
                                        EASY RETURNS &amp; QUICK REFUNDS
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-row  containerInner">
                                    <div className="d-flex flex-column align-items-center">
                                      <img
                                        loading="lazy"
                                        alt="offer"
                                        src="https://images.bewakoof.com/web/quality-check.svg"
                                      />
                                      <span className="ProductText">
                                        QUALITY ASSURANCE
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden lg:block md:block xl:block 2xl:block">
                  <div
                    className="container"
                    style={{ maxWidth: 750, margin: "0px auto" }}
                  />
                  <span id="AddressUpdate">
                    <div />
                  </span>
                  <div className="countryListWrapper">
                    <span id="AllCountries">
                      <div />
                    </span>
                  </div>
                  <div
                    style={{
                      marginTop: 0,
                      textAlign: "center",
                      background: "rgb(251, 251, 251)",
                    }}
                  >
                    <img
                      src="https://images.bewakoof.com/web/secure-payments-image.png"
                      title="Secure Payments"
                      alt="Secure Payments"
                      style={{
                        maxWidth: 257,
                        width: "100%",
                        margin: "10px auto",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="inline w-full lg:hidden md:hidden xl:hidden 2xl:hidden">
              <div className="addButtonsWrpr">
                <div className="pmts-wrap">
                  <div className="pmts-pr">
                    <span>Total</span>
                    <div className="d-flex">
                      <p>₹</p>
                      <p>{cartinfoData.total}</p>
                    </div>
                  </div>
                  <a
                    id="os_payNow_btn"
                    className="pmts-btn text-center"
                    style={{
                      backgroundColor: "rgb(66, 162, 162)",
                      borderColor: "rgb(66, 162, 162)",
                      color: "white",
                    }}
                    href="/checkout"
                  >
                    Go to checkout
                  </a>
                </div>
              </div>

              {/* <a
                id="addButtons"
                className="addButtonsWrpr w-full"
                href="/checkout"
              >
                <div
                  className="addButtons d-flex flex-row align-items-center flex-row flex-grow-1  "
                  style={{ opacity: 1, pointerEvents: "auto" }}
                >
                  <div
                    className="p-add-bag bg-yellow-300 hover:bg-yellow-400 btn-border d-flex flex-row align-items-center flex-row align-items-center justify-content-center cursor-p "
                    style={{ flex: "1 1 0%" }}
                  >
                    <span>GO TO CHECKOUT</span>
                  </div>
                </div>
              </a> */}
            </div>

            <div className="inline w-full lg:hidden md:hidden xl:hidden 2xl:hidden">
              <div className="cartInline">
                <div className="trustBaggeContainer d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-row  containerInner">
                      <div className="d-flex flex-column align-items-center">
                        <img
                          loading="lazy"
                          alt="offer"
                          src="https://images.bewakoof.com/web/cart-badge-trust.svg"
                        />
                        <span className="ProductText">
                          100% SECURE PAYMENTS
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-row  containerInner">
                      <div className="d-flex flex-column align-items-center">
                        <img
                          loading="lazy"
                          alt="offer"
                          src="https://images.bewakoof.com/web/cart-easy-return.svg"
                        />
                        <span className="ProductText">
                          EASY RETURNS &amp; QUICK REFUNDS
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-row  containerInner">
                      <div className="d-flex flex-column align-items-center">
                        <img
                          loading="lazy"
                          alt="offer"
                          src="https://images.bewakoof.com/web/quality-check.svg"
                        />
                        <span className="ProductText">QUALITY ASSURANCE</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="inline w-full lg:hidden md:hidden xl:hidden 2xl:hidden">
              <div
                className="container"
                style={{ maxWidth: 750, margin: "0px auto" }}
              />
              <span id="AddressUpdate">
                <div />
              </span>
              <div className="countryListWrapper">
                <span id="AllCountries">
                  <div />
                </span>
              </div>
              <div
                style={{
                  marginTop: 0,
                  textAlign: "center",
                  background: "rgb(251, 251, 251)",
                }}
              >
                <img
                  src="https://images.bewakoof.com/web/secure-payments-image.png"
                  title="Secure Payments"
                  alt="Secure Payments"
                  style={{
                    maxWidth: 257,
                    width: "100%",
                    margin: "10px auto",
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="container mx-auto lg:px-4 md:px-4"
            hidden={!(getcartCount() == 0)}
          >
            <div className="p-4 lg:p-4s bg-white">
              <div className="cartDeskHead container">
                <span className="qty">
                  <b>My Cart </b>
                </span>
              </div>
              <div className="flex flex-wrap items-center -mx-4">
                <div className="w-full">
                  <br />
                  <br />
                  <center>
                    {/* <BsFillCartXFill
                      color="gray"
                      style={{ height: "100px", width: "100px" }}
                    /> */}
                    <img src="yofte-assets/loader/empty-cart.webp" alt="" />
                    <br />
                    <h3>
                      <b>Cart is empty.</b>
                    </h3>
                    <p>
                      Looks like you have no items in your shopping cart. <br />
                      Click{" "}
                      <a href="/products" style={{ color: "blue" }}>
                        here
                      </a>{" "}
                      to continue shopping.
                    </p>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>

        {FooterInCart(
          showFooter,
          store,
          footercopyrighttext,
          setshowFooter,
          setCount,
          count
        )}

        {/* <section className="py-6 bg-blue-300">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap -mx-4 pb-6 lg:pb-16 border-b border-gray-400">
              <div className="w-full lg:w-3/5 px-4 mb-20">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-8 text-xl font-heading text-white">
                      Information
                    </h3>
                    <ul>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Newsroom
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Story
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Yofte Careers
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Investor Relations
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Reward program
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Delivery information
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Paying by invoice
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-8 text-xl font-heading text-white">
                      Customer Service
                    </h3>
                    <ul>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Return an order
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Search Terms
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Advanced Search
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Orders and Returns
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          FAQs
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Store Location
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-4">
                    <h3 className="mb-8 text-xl text-white font-heading">
                      Contact Us
                    </h3>
                    <ul>
                      <li className="mb-6">
                        <h4 className="mb-2 text-gray-50">Mobile</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          +91 95 517 89459
                        </a>
                      </li>
                      <li className="mb-6">
                        <h4 className="mb-2 text-gray-50">Email</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          hi@teemania.com
                        </a>
                      </li>
                      <li>
                        <h4 className="mb-2 text-gray-50">Live Chat</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          teemania
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-2/5 px-4 order-first lg:order-1 mb-20">
                <h3 className="mb-6 text-xl text-white font-heading">
                  Join our Newsletter
                </h3>
                <p className="mb-8 text-xl text-yellow-500 font-heading">
                  News, sales:
                </p>
                <div className="mb-6 relative lg:max-w-xl lg:mx-auto bg-white rounded-lg">
                  <div className="relative flex flex-wrap items-center justify-between">
                    <div className="relative flex-1">
                      <span className="absolute top-0 left-0 ml-8 mt-4 font-semibold font-heading text-xs text-gray-400">
                        Drop your e-mail
                      </span>
                      <input
                        className="inline-block w-full pt-8 pb-4 px-8 placeholder-gray-900 border-0 focus:ring-transparent focus:outline-none rounded-md"
                        type="text"
                        placeholder="print@teemania.in"
                      />
                    </div>
                    <a
                      className="inline-block w-auto bg-orange-300 hover:bg-orange-400 text-white font-heading py-6 px-8 rounded-md uppercase text-center"
                      href={() => {
                        return false;
                      }}
                    >
                      Join
                    </a>
                  </div>
                </div>
                <p className="text-gray-200">
                  The brown fox jumps over the lazy dog.
                </p>
              </div>
              <div className="w-full px-4 flex flex-wrap justify-between lg:order-last">
                <div className="w-full md:w-auto mb-4 md:mb-0 flex flex-wrap">
                  <img
                    className="mr-4 mb-2"
                    src="yofte-assets/brands/visa.svg"
                    alt=""
                  />
                  <img
                    className="mr-4 mb-2"
                    src="yofte-assets/brands/paypal.svg"
                    alt=""
                  />
                  <img
                    className="mb-2"
                    src="yofte-assets/brands/mastercard.svg"
                    alt=""
                  />
                </div>
                <div className="w-full md:w-auto flex">
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="yofte-assets/buttons/facebook-white-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="yofte-assets/buttons/instagram-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img src="yofte-assets/buttons/twitter-circle.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="pt-8 flex items-center justify-center">
              <a
                className="inline-block mr-4 text-white text-2xl font-heading"
                href="/"
              >
                <img
                  className="h-7"
                  src={`yofte-assets/logos/${lowercasenosp(
                    store
                  )}/logowhite.webp`}
                  alt=""
                  width="auto"
                />
              </a>
              <p className="inline-block text-sm text-gray-200">
                © Copyright 2021 Teemania
              </p>
            </div>
          </div>
        </section> */}
      </>
    </React.Fragment>
  );
}

function FooterInCart(
  showFooter,
  store,
  footercopyrighttext,
  setshowFooter,
  setCount,
  count
) {
  return (
    <>
      <section className={"inline-block py-4 bg-blue-300 footerStyle"}>
        <div className="container mx-auto px-2">
          <div className="flex flex-wrap -mx-4 pb-6 lg:pb-6 border-b border-gray-400">
            <div className="w-full lg:w-3/5 px-4 mb-6">
              <div className="flex flex-wrap -mx-4">
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                  <h3 className="mb-2 text-xl font-bold font-heading text-white">
                    Information
                  </h3>
                  <ul>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Newsroom
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Story
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Yofte Careers
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Investor Relations
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Reward program
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Delivery information
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Paying by invoice
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                  <h3 className="mb-2 text-xl font-bold font-heading text-white">
                    Customer Service
                  </h3>
                  <ul>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Return an order
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Search Terms
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Advanced Search
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Orders and Returns
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        FAQs
                      </a>
                    </li>
                    <li className="mb-2">
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Store Location
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-gray-50 hover:text-gray-200"
                        href={() => {
                          return false;
                        }}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-4">
                  <h3 className="mb-2 text-xl text-white font-bold font-heading">
                    Contact Us
                  </h3>
                  <ul>
                    <li className="mb-2">
                      <h4 className="mb-2 text-gray-50">Mobile</h4>
                      <a
                        className="text-white hover:underline"
                        href={() => {
                          return false;
                        }}
                      >
                        +91 95 517 89459
                      </a>
                    </li>
                    <li className="mb-2">
                      <h4 className="mb-2 text-gray-50">Email</h4>
                      <a
                        className="text-white hover:underline"
                        href={() => {
                          return false;
                        }}
                      >
                        hi@teemania.com
                      </a>
                    </li>
                    <li>
                      <h4 className="mb-2 text-gray-50">Live Chat</h4>
                      <a
                        className="text-white hover:underline"
                        href={() => {
                          return false;
                        }}
                      >
                        teemania
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-2/5 px-4 order-first lg:order-1 mb-2">
              <h3 className="mb-2 text-xl text-white font-bold font-heading">
                Join our Newsletter
              </h3>
              <p className="mb-2 text-xl text-yellow-500 font-bold font-heading">
                News, sales:
              </p>
              <div className="mb-2 relative lg:max-w-xl lg:mx-auto bg-white rounded-lg">
                <div className="relative flex flex-wrap items-center justify-between">
                  <div className="relative flex-1">
                    {/* <span className="absolute top-0 left-0 ml-8 mt-4 font-semibold font-heading text-xs text-gray-400">
        Drop your e-mail
      </span> */}
                    <input
                      className="inline-block w-full px-8 placeholder-gray-900 border-0 focus:ring-transparent focus:outline-none rounded-md"
                      type="text"
                      placeholder="Drop your e-mail"
                    />
                  </div>
                  <a
                    className="inline-block w-auto bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-2 px-8 rounded-md uppercase text-center"
                    href={() => {
                      return false;
                    }}
                  >
                    Join
                  </a>
                </div>
              </div>
              <p className="text-gray-200">
                The brown fox jumps over the lazy dog.
              </p>
            </div>
            <div className="w-full px-4 flex flex-wrap justify-between lg:order-last">
              <div className="w-full md:w-auto mb-4 md:mb-0 flex flex-wrap">
                <img
                  className="mr-4 mb-2"
                  src="yofte-assets/brands/visa.svg"
                  alt=""
                />
                <img
                  className="mr-4 mb-2"
                  src="yofte-assets/brands/paypal.svg"
                  alt=""
                />
                <img
                  className="mb-2"
                  src="yofte-assets/brands/mastercard.svg"
                  alt=""
                />
              </div>
              <div className="w-full md:w-auto flex">
                <a
                  className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                  href={() => {
                    return false;
                  }}
                >
                  <img
                    src="yofte-assets/buttons/facebook-white-circle.svg"
                    alt=""
                  />
                </a>
                <a
                  className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                  href={() => {
                    return false;
                  }}
                >
                  <img src="yofte-assets/buttons/instagram-circle.svg" alt="" />
                </a>
                <a
                  className="inline-flex items-center justify-center w-12 h-12 rounded-full"
                  href={() => {
                    return false;
                  }}
                >
                  <img src="yofte-assets/buttons/twitter-circle.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="pt-8 flex items-center justify-center">
            <a
              className="inline-block mr-4 text-white text-2xl font-bold font-heading"
              href="/"
            >
              <img
                className="h-7"
                src={`yofte-assets/logos/${lowercasenosp(
                  store
                )}/logowhite.webp`}
                alt=""
                width="auto"
              />
            </a>
            <p className="inline-block text-sm text-gray-200">
              {footercopyrighttext}
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

function NavBarCart(store, getcartCount, userData) {
  return (
    <section className="relative">
      <nav
        style={{ fontWeight: 500, fontSize: "13px" }}
        className="flex justify-between border-b navcustombg"
      >
        <div className="px-6 py-2 flex w-full items-center">
          <ul className="hidden xl:flex font-heading">
            <li className="mr-12">
              <a className="hover:text-gray-600" href="/products">
                MEN
              </a>
            </li>
            <li className="mr-12">
              <a className="hover:text-gray-600" href="/products">
                WOMEN
              </a>
            </li>
            <li className="mr-12">
              <a className="hover:text-gray-600" href="/products">
                KIDS
              </a>
            </li>
            <li className="mr-12">
              <a className="hover:text-gray-600" href="/products">
                COUPLE COLLECTION
              </a>
            </li>
            {/* <li className="mr-12">
          <a className="hover:text-gray-600" href="/products">
            Apparels
          </a>
        </li> */}
            <li>
              <a className="hover:text-gray-600" href="/products">
                POLITICAL SHIRTS
              </a>
            </li>
          </ul>
          <a
            className="flex-shrink-0 xl:mx-auto text-3xl font-heading"
            href="/"
          >
            <img
              style={{ height: "38px" }}
              className="h-12"
              src={`yofte-assets/logos/${lowercasenosp(store)}/logo.webp`}
              alt=""
              width="auto"
            />
          </a>
          {/* <div className="hidden xl:inline-block mr-14">
          <input
            className="py-5 px-8 w-full placeholder-gray-400 text-xs uppercase font-semibold font-heading bg-gray-50 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
            type="text"
            placeholder="Search"
          />
        </div> */}
          <div className="hidden xl:flex items-center">
            <a className="mr-10 hover:text-gray-600" href="#">
              <svg
                width={23}
                height={20}
                viewBox="0 0 23 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.4998 19.2061L2.70115 9.92527C1.92859 9.14433 1.41864 8.1374 1.24355 7.04712C1.06847 5.95684 1.23713 4.8385 1.72563 3.85053V3.85053C2.09464 3.10462 2.63366 2.45803 3.29828 1.96406C3.9629 1.47008 4.73408 1.14284 5.5483 1.00931C6.36252 0.875782 7.19647 0.939779 7.98144 1.19603C8.7664 1.45228 9.47991 1.89345 10.0632 2.48319L11.4998 3.93577L12.9364 2.48319C13.5197 1.89345 14.2332 1.45228 15.0182 1.19603C15.8031 0.939779 16.6371 0.875782 17.4513 1.00931C18.2655 1.14284 19.0367 1.47008 19.7013 1.96406C20.3659 2.45803 20.905 3.10462 21.274 3.85053V3.85053C21.7625 4.8385 21.9311 5.95684 21.756 7.04712C21.581 8.1374 21.071 9.14433 20.2984 9.92527L11.4998 19.2061Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
            <a className="flex items-center hover:text-gray-600" href="/cart">
              <svg
                className="mr-3"
                width={23}
                height={23}
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span
                style={{ padding: "3px" }}
                className="inline-block w-6 h-6 text-center text-white bg-orange-300  hover:bg-orange-400 rounded-full font-heading"
              >
                {getcartCount()}
              </span>
            </a>
          </div>
        </div>
        <div className="hidden xl:flex items-center px-12 border-l font-heading hover:text-gray-600">
          <a
            href="/signin"
            style={{
              display: userData != null ? "none" : "flex",
            }}
          >
            <svg
              className="mr-3"
              width={32}
              height={31}
              viewBox="0 0 32 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span style={{ width: "150px", paddingTop: "5px" }}>
              SIGN&nbsp;IN
            </span>
          </a>

          <a
            className="dropdown"
            href={() => {
              return false;
            }}
            style={{
              display: userData === null ? "none" : "flex",
              cursor: "pointer",
            }}
          >
            <svg
              className="mr-3"
              width={32}
              height={31}
              viewBox="0 0 32 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span style={{ width: "150px", paddingTop: "5px" }}>
              {userData?.name}
            </span>
            <div className="dropdown-content">
              <a href="/myprofile">My Profile</a>
              <a href="/orderhistory">My Orders</a>
              <a href="/changepassword">Change Password</a>
              <hr
                style={{
                  border: "1px solid #CFD5E2",
                  height: "2px",
                }}
              />
              <a href="/signin">Logout</a>
            </div>
          </a>
        </div>

        <a
          className="xl:hidden flex mr-6 items-center text-gray-900"
          href="/cart"
        >
          <svg
            className="mr-2"
            width={23}
            height={23}
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span
            style={{ padding: "3px" }}
            className="inline-block w-6 h-6 text-center text-white bg-orange-300  hover:bg-orange-400 rounded-full font-heading"
          >
            {getcartCount()}
          </span>
        </a>
        <a
          className="navbar-burger self-center mr-12 xl:hidden"
          href={() => {
            return false;
          }}
          onClick={() => {
            document.getElementById("mySidenav").style.width = "290px";
          }}
        >
          <svg
            width={20}
            height={12}
            viewBox="0 0 20 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM19 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10ZM19 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H19C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z"
              fill="#8594A5"
            />
          </svg>
        </a>
        <div id="mySidenav" className="sidenav mr-12 xl:hidden">
          <a
            href={() => {
              return false;
            }}
            className="closebtn"
            onClick={() => {
              document.getElementById("mySidenav").style.width = "0";
            }}
          >
            &times;
          </a>
          <div className="xl:flex items-center border-l font-heading hover:text-gray-600">
            <a
              href="/signin"
              style={{
                display: userData != null ? "none" : "flex",
              }}
            >
              <svg
                className="mr-3"
                width={32}
                height={31}
                viewBox="0 0 32 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span style={{ width: "150px", paddingTop: "5px" }}>
                SIGN&nbsp;IN
              </span>
            </a>

            <a
              className="dropdown"
              href="#"
              style={{
                display: userData === null ? "none" : "flex",
                cursor: "pointer",
              }}
            >
              <svg
                className="mr-3"
                width={32}
                height={31}
                viewBox="0 0 32 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span style={{ width: "150px", paddingTop: "0px" }}>
                {userData?.name}
              </span>
              <div className="dropdown-content-nav">
                <a href="/myprofile">My Profile</a>
                <a href="/orderhistory">My Orders</a>
                <a href="/changepassword">Change Password</a>
                <hr
                  style={{
                    border: "1px solid #CFD5E2",
                    height: "2px",
                  }}
                />
                <a href="/signin">Logout</a>
              </div>
            </a>
          </div>
          <hr
            style={{
              border: "1px solid #CFD5E2",
              height: "2px",
            }}
          />
          <ul className="xl:flex" style={{ fontSize: "6px" }}>
            <li className="mr-12 ">
              <a
                style={{ display: "flex" }}
                className="hover:text-gray-600"
                href="/products"
              >
                🕺 &nbsp;&nbsp; Men
              </a>
            </li>
            <li className="mr-12">
              <a
                style={{ display: "flex" }}
                className="hover:text-gray-600"
                href="/products"
              >
                💃 &nbsp;&nbsp; Women
              </a>
            </li>
            <li className="mr-12">
              <a
                style={{ display: "flex" }}
                className="hover:text-gray-600"
                href="/products"
              >
                👶 &nbsp;&nbsp; Kids
              </a>
            </li>
            <li className="mr-12">
              <a
                style={{ display: "flex" }}
                className="hover:text-gray-600"
                href="/products"
              >
                👩‍❤️‍👨 &nbsp;&nbsp; Couple Collection
              </a>
            </li>
            {/* <li className="mr-12">
          <a className="hover:text-gray-600" href="/products">
            Apparels
          </a>
        </li> */}
            <li>
              <a
                style={{ display: "flex" }}
                className="hover:text-gray-600"
                href="/products"
              >
                🚩 &nbsp;&nbsp; Political Shirts
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </section>
  );
}

import React from "react";
import imgBan2 from "./ban-img--2.jpg";
import imgBan from "./ban-Img1-.webp";
import img1 from "./Accessories-img/-1.webp";
import img2 from "./Accessories-img/2-.webp";
import img3 from "./Accessories-img/3-.jpg";
import img4 from "./Accessories-img/4-.jpg";
import "./Accessories.css";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// import Button from 'react-bootstrap/Button';

// or less ideally

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
export const Accessories = () => {
  return (
    <>
      <div className="mt-5">
        <div className="block lg:flex ">
          <img
            src={imgBan}
            className="w-full lg:w-1/2 img mt-4 img-fluid"
          ></img>

          <img
            src={imgBan2}
            className="w-full lg:w-1/2 img mt-4 img-fluid"
          ></img>
        </div>
        <br />
        <center>
          <h4>Accessories</h4>
        </center>
        <br />
        <Swiper
          style={{ height: "fit-content" }}
          className="Men-Swiper mb-5"
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={0}
          loop={true}
          autoplay={{
            delay: 4000,
            reverseDirection: true,
            disableOnInteraction: false,
          }}
          slidesPerView={2}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          breakpoints={{
            // when window width is >= 640px
            640: {
              width: "auto",
              slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 6,
            },
          }}
        >
          <SwiperSlide className="men-swiperslider mt-5">
            <img src={img1} />
            {/* <Card className="Card" style={{ width: "18rem" }}>
            <Card.Img variant="top" src={img1} />
            <Card.Body>
              <Card.Title>Men Clothes</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </Card> */}
          </SwiperSlide>
          <SwiperSlide className="men-swiperslider mt-5">
            <img src={img2} />
            {/* <Card className="Card" style={{ width: "18rem" }}>
            <Card.Img variant="top" src={img2} />
            <Card.Body>
              <Card.Title>Men Clothes</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </Card> */}
          </SwiperSlide>
          <SwiperSlide className="men-swiperslider mt-5">
            <img src={img3} />
            {/* <Card className="Card" style={{ width: "18rem" }}>
            <Card.Img variant="top" src={img3} />
            <Card.Body>
              <Card.Title>Men Clothes</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </Card> */}
          </SwiperSlide>
          <SwiperSlide className="men-swiperslider mt-5">
            <img src={img4} />
            {/* <Card className="Card" style={{ width: "18rem" }}>
            <Card.Img variant="top" src={img4} />
            <Card.Body>
              <Card.Title>Men Clothes</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </Card> */}
          </SwiperSlide>
          <SwiperSlide className="men-swiperslider mt-5">
            <img src={img1} />
            {/* <Card className="Card" style={{ width: "18rem" }}>
            <Card.Img variant="top" src={img5} />
            <Card.Body>
              <Card.Title>Men Clothes</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </Card> */}
          </SwiperSlide>
          <SwiperSlide className="men-swiperslider mt-5">
            <img src={img2} />
          </SwiperSlide>
        </Swiper>
        <style></style>
      </div>
    </>
  );
};

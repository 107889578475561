import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import img1 from "../Men-Product-img/1-.jpg";
import img2 from "../Men-Product-img/2-.jpg";
import img3 from "../Men-Product-img/3-.webp";
import img4 from "../Men-Product-img/4-.jpg";
import img5 from "../Men-Product-img/5-.jpg";
import img6 from "../Men-Product-img/6-.jpg";
// import Button from 'react-bootstrap/Button';

// or less ideally

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "./MensProduct.css";

export default () => {
  return (
    <div className="mt-5">
      <br />
      <center>
        <h4>Mens Clothes</h4>
      </center>
      <br />
      <Swiper
        style={{ height: "fit-content" }}
        className="Men-Swiper"
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        spaceBetween={0}
        loop={true}
        autoplay={{
          delay: 4000,
          reverseDirection: true,
          disableOnInteraction: false,
        }}
        slidesPerView={2}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
        breakpoints={{
          // when window width is >= 640px
          640: {
            width: "auto",
            slidesPerView: 2,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 6,
          },
        }}
      >
        <SwiperSlide className="men-swiperslider mt-5">
          <img src={img1} />
          {/* <Card className="Card" style={{ width: "18rem" }}>
            <Card.Img variant="top" src={img1} />
            <Card.Body>
              <Card.Title>Men Clothes</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </Card> */}
        </SwiperSlide>
        <SwiperSlide className="men-swiperslider mt-5">
          <img src={img2} />
          {/* <Card className="Card" style={{ width: "18rem" }}>
            <Card.Img variant="top" src={img2} />
            <Card.Body>
              <Card.Title>Men Clothes</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </Card> */}
        </SwiperSlide>
        <SwiperSlide className="men-swiperslider mt-5">
          <img src={img3} />
          {/* <Card className="Card" style={{ width: "18rem" }}>
            <Card.Img variant="top" src={img3} />
            <Card.Body>
              <Card.Title>Men Clothes</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </Card> */}
        </SwiperSlide>
        <SwiperSlide className="men-swiperslider mt-5">
          <img src={img4} />
          {/* <Card className="Card" style={{ width: "18rem" }}>
            <Card.Img variant="top" src={img4} />
            <Card.Body>
              <Card.Title>Men Clothes</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </Card> */}
        </SwiperSlide>
        <SwiperSlide className="men-swiperslider mt-5">
          <img src={img5} />
          {/* <Card className="Card" style={{ width: "18rem" }}>
            <Card.Img variant="top" src={img5} />
            <Card.Body>
              <Card.Title>Men Clothes</Card.Title>
              <Card.Text></Card.Text>
            </Card.Body>
          </Card> */}
        </SwiperSlide>
        <SwiperSlide className="men-swiperslider mt-5">
          <img src={img6} />
        </SwiperSlide>
      </Swiper>
      <style></style>
    </div>
  );
};
